import {InfoOutlined} from '@mui/icons-material'
import {Stack, Tooltip} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

import {RUNNING_TIME_COLOR} from './common'
import {LegendItem} from './LegendItem'

type OperationHoursLegendTooltipProps = {
  translationPrefix?: string
}

export const OperationHoursLegendTooltip: FC<OperationHoursLegendTooltipProps> = ({
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <Tooltip
      enterTouchDelay={0}
      title={
        <Stack spacing={0.5} ml={{sm: 1}}>
          <LegendItem
            color={RUNNING_TIME_COLOR.running}
            label={t(getTranslationKey('runningTime.legend.running', translationPrefix))}
          />
          <LegendItem
            square
            color={RUNNING_TIME_COLOR.stopped}
            label={t(getTranslationKey('runningTime.legend.unplannedStoppage', translationPrefix))}
          />
          <LegendItem
            square
            color={RUNNING_TIME_COLOR.planned}
            label={t(getTranslationKey('runningTime.legend.plannedStoppage', translationPrefix))}
          />
        </Stack>
      }
    >
      <InfoOutlined sx={{color: 'secondary.main', ml: 1.5}} />
    </Tooltip>
  )
}
