import {PercentPieChart} from '@hconnect/uikit/src/lib2'
import {Box, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentExpertSystemUsage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {ContentBox} from '../ContentBox'

export const ExpertSystemEquipmentCharts: React.FC<{
  expertSystemEquipments: EquipmentExpertSystemUsage[]
  translationPrefix?: string
  isLoading?: boolean
}> = ({expertSystemEquipments, translationPrefix, isLoading}) => {
  const {t} = useTranslation()
  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={t(getTranslationKey('expertSystemUtilization.title', translationPrefix))}
      bodyWithPadding
      data-test-id="expert-sys-util"
      isLoading={isLoading}
    >
      <Box display="flex" sx={{flexWrap: 'wrap'}}>
        {expertSystemEquipments.map(({expertSystemOnPercentage, equipmentNumber, processStage}) => (
          <Tooltip
            title={t(
              getTranslationKey(
                `expertSystemUtilization.processStage.${processStage}`,
                translationPrefix
              )
            )}
            key={`${equipmentNumber}-${processStage}-tooltip`}
          >
            <span>
              <PercentPieChart
                percent={expertSystemOnPercentage}
                text={equipmentNumber}
                key={`${equipmentNumber}-${processStage}`}
                data-test-id={`expert-system-eq-${equipmentNumber}-${processStage}`}
              />
            </span>
          </Tooltip>
        ))}
      </Box>
    </ContentBox>
  )
}
