export enum QuestionDifficulty {
  Basic = 'basic',
  Advanced = 'advanced',
  Excellence = 'excellence'
}

export enum AnswerStatus {
  Positive = 'positive',
  Negative = 'negative',
  NotAvailable = 'notAvailable',
  Empty = 'empty'
}

export interface DifficultSummary {
  answeredPositively: number
  questionCount: number
  positivePercent: number
}

export type DifficultySummaries = Record<QuestionDifficulty, DifficultSummary>

export interface Scoring {
  score: number
  completion: number
  difficultySummaries: DifficultySummaries
}

export interface QuestionDto {
  id: string
  difficulty: QuestionDifficulty
  submission?: {
    answer: AnswerStatus
  }
}

export interface TopicDto {
  topicId: string
  sectionId: string
  name: string
  guidanceLink?: string
  scoring?: Scoring
  responsible?: UserDto
  questions?: QuestionDto[]
}

export interface SectionDto {
  sectionId: string
  name: string
  scoring?: Scoring
  responsible?: UserDto
  topics: TopicDto[]
  guidanceLink?: string
}

export interface UserDto {
  userId: string
  name: string
}

export interface PlantDto {
  plantId: string
  name: string
  lastUpdate?: string
  locationId: string
  timezone: string
  scoring: Scoring
  responsible?: UserDto
  sections: SectionDto[]
}
