import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/WarningAmber'

import {KpiRangeBreachSeverity} from '../../types'

const KpiThresholdIcons = {
  [KpiRangeBreachSeverity.Alarm]: ErrorIcon,
  [KpiRangeBreachSeverity.Warning]: WarningIcon,
  [KpiRangeBreachSeverity.Optimal]: CheckCircleIcon
} as const

export const KpiThreadholdColors = {
  [KpiRangeBreachSeverity.Alarm]: 'error',
  [KpiRangeBreachSeverity.Warning]: 'warning',
  [KpiRangeBreachSeverity.Optimal]: 'success'
} as const

export const KpiThresholdIcon = ({
  status,
  dataTestId
}: {
  status?: KpiRangeBreachSeverity
  dataTestId?: string
}) => {
  if (!status) return null

  const Icon = KpiThresholdIcons[status]
  const color = KpiThreadholdColors[status]

  return <Icon fontSize="small" color={color} data-test-id={dataTestId} />
}
