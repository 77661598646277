// These functions are kind of "weird" and should only be used to normalize the DatePicker
// Takes a date in UTC and a timezone, calculates the utcOffset for that timezone, and normalizes
// the date to be still in UTC format, but in "Etc/UTC" timezone
import moment, {Moment} from 'moment-timezone'

export function convertDateToUtc(date: Moment, fromTimezone: string): Moment {
  const timezoned = moment.tz(date, fromTimezone)
  const offset = timezoned.utcOffset()
  return timezoned.clone().add(offset, 'minutes').utc()
}

export function convertDateUtcToLocalTime(date: Moment, toTimezone: string) {
  const timezoned = moment.tz(date, toTimezone)
  const offset = timezoned.utcOffset()
  return timezoned.clone().subtract(offset, 'minutes').utc()
}

export function fromBrowsertimeToPlantTime(date: Moment, fromTimezone: string, toTimezone: string) {
  return convertDateUtcToLocalTime(convertDateToUtc(date, fromTimezone), toTimezone)
}

/**
 * Returns the hours and minutes of a time string
 * @param time 'HH:mm:ss'
 * @returns 'HH:mm'
 */
export const getHoursMinutes = (time?: string) => {
  if (!time) return ''
  const [hours, minutes] = time.split(':')
  return `${hours}:${minutes}`
}
