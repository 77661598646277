import {Block, Done, Remove, Loop, HourglassEmpty, Check, CancelOutlined} from '@mui/icons-material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {StatusWithNoneAndCancellationAndApproverStates} from '../../types'
import {IconWithLabel} from '../shiftEventLabels'

export const EventStatusLabel: React.FC<{
  status: StatusWithNoneAndCancellationAndApproverStates
  iconOnly?: boolean
  sx?: SystemStyleObject
  t?: TFunction | ((key: string) => string)
}> = (props) => {
  const {t} = useTranslation()
  const translate = props.t || t

  const icons: Record<StatusWithNoneAndCancellationAndApproverStates, React.ReactNode> = {
    pending: <HourglassEmpty color={'error'} />,
    ongoing: <Loop color={'secondary'} />,
    done: <Done color={'success'} />,
    cancelled: <Block color={'secondary'} />,
    none: <Remove />,
    approved: <Check color={'success'} />,
    rejected: <CancelOutlined color={'error'} />,
    waitingForApproval: <HourglassEmpty />
  }

  return (
    <IconWithLabel
      sx={props.sx}
      icon={icons[props.status as string] ?? <Remove />}
      iconOnly={props.iconOnly}
      label={translate(`shiftEvent.status.${props.status}`)}
    />
  )
}
