import {Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {orderBy} from 'lodash'
import React from 'react'

import {getRunningTimeData} from '../components/runningTimes'
import {RunningTimeDropDownItem} from '../components/shiftEventFormFields/StoppageTimeDropDown'
import {
  EquipmentCategory,
  EquipmentData,
  EquipmentRunningTimes,
  RunningTime,
  RunningTimeType,
  Stoppage,
  StoppageCode,
  StoppageKind,
  StoppageType,
  StoppageTypeWithCode
} from '../types'

export const isStoppageTypeWithReason = (stoppageType: StoppageType): boolean =>
  stoppageType === 'other' ||
  stoppageType === 'coalMill' ||
  stoppageType === 'crusher' ||
  stoppageType === 'alternativeFuelInstallation' ||
  stoppageType === 'bypass'

export const isStoppageTypeWithCode = (stoppageType: StoppageType): boolean =>
  stoppageType === 'kiln' || stoppageType === 'cementMill' || stoppageType === 'rawMill'

export const stoppageDefaultObject = (): Partial<Stoppage> => {
  return {
    eventType: StoppageKind.Incident,
    status: 'none',
    stoppageType: 'kiln',
    title: '',
    stoppageCode: undefined,
    description: ''
  }
}

export const getStoppageDescription = (
  item: Stoppage,
  stoppageCodes: {[key in StoppageTypeWithCode]: StoppageCode[]}
) => {
  if ('stoppageType' in item && 'stoppageCode' in item) {
    const {stoppageType, stoppageCode} = item
    const stoppage = stoppageCodes[stoppageType].find((item) => item.code === stoppageCode)
    return stoppage?.description
  }
  return null
}

export const getRunningTimeStoppageLabel = (
  stoppageCodes: {[key in StoppageTypeWithCode]: StoppageCode[]},
  rt?: RunningTime,
  stoppageType?: StoppageType
) => {
  if (stoppageType && rt?.stoppageCode) {
    const stoppage = stoppageCodes[stoppageType]?.find((item) => item.code === rt.stoppageCode)
    const stoppageDescription = stoppage?.description
    return (
      <Typography variant="h5">
        {rt.stoppageCode}
        {stoppageDescription && ` - ${stoppageDescription}`}
      </Typography>
    )
  }
}

export const getRunningTimeDropdownItems = (
  runningTimes: EquipmentRunningTimes[],
  language: string,
  t: TFunction
): RunningTimeDropDownItem[] =>
  orderBy(
    runningTimes
      .map(({runningTimes, equipment}) => {
        return runningTimes
          .filter(
            ({runningTimeType, cockpitStoppageId}) =>
              runningTimeType === RunningTimeType.STOPPED && !cockpitStoppageId
          )
          .map((runningTime) => {
            return {
              label: getRunningTimeData(runningTime, language, t),
              runningTime,
              equipment: equipment
            }
          })
      })
      .flat()
      .map((runningTime, index) => ({...runningTime, id: String(index)})),
    ({runningTime}) => new Date(runningTime.beginTechnical || ''),
    'desc'
  )

export const STOPPAGE_TYPE_MAP: Record<EquipmentCategory, StoppageType> = {
  [EquipmentCategory.CEMENT]: 'cementMill',
  [EquipmentCategory.RAW]: 'rawMill',
  [EquipmentCategory.COAL]: 'coalMill',
  [EquipmentCategory.CLINKER]: 'kiln',
  [EquipmentCategory.UNKNOWN]: 'other'
}

export const getEventStoppageType = (equipment: EquipmentData): StoppageType => {
  if (equipment.equipmentType === 'kiln') {
    return 'kiln'
  } else if (equipment.equipmentCategory) {
    return STOPPAGE_TYPE_MAP[equipment.equipmentCategory]
  } else {
    return 'other'
  }
}
