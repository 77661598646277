import {formatTimeZoneDate} from '@hconnect/uikit'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

import {CommentMoreButton} from './CommentMoreButton'
import {CommonComment, EditCommentProps, DeleteCommentProps} from './comments.types'

type CommentItemProps<T> = {
  comment: T
  timeZone: string
  deleteCommentProps?: DeleteCommentProps
  editCommentProps?: EditCommentProps
  renderPrefix?: (commentId: string) => ReactNode | undefined
  translationPrefix?: string
  noTextGutter?: boolean
  dateFormat?: string
  customCommentPostfix?: (comment: T) => ReactNode
}

export const CommentItem = <T extends CommonComment>({
  comment,
  deleteCommentProps,
  editCommentProps,
  renderPrefix,
  timeZone,
  translationPrefix,
  noTextGutter,
  dateFormat = 'lll',
  customCommentPostfix
}: CommentItemProps<T>) => {
  const {t, i18n} = useTranslation()

  return (
    <Box display="flex" flexDirection="row" py={1} data-test-id={`comment-${comment.id}`}>
      <>
        <Box flex={1} pr={2}>
          {renderPrefix?.(comment.id)}
          <Typography component="div" gutterBottom={!noTextGutter} data-test-id="comment-item-text">
            <MarkdownText>{comment.text}</MarkdownText>
          </Typography>
          {customCommentPostfix?.(comment) || (
            <Typography variant="caption" data-test-id="comment-author-date-label">
              {t(getTranslationKey('comments.byOn', translationPrefix), {
                by: comment.updatedBy ?? comment.createdBy,
                on: formatTimeZoneDate(
                  comment.updatedOn ?? comment.createdOn,
                  timeZone,
                  dateFormat,
                  i18n.language
                )
              })}
            </Typography>
          )}
        </Box>
        {!!(deleteCommentProps && editCommentProps) && (
          <Box data-test-id="comment-item-actions">
            <CommentMoreButton
              comment={comment}
              deleteCommentProps={deleteCommentProps}
              moreButtonTestId={`commentItem-more-button-${comment.id}`}
              editCommentProps={editCommentProps}
              translationPrefix={translationPrefix}
            />
          </Box>
        )}
      </>
    </Box>
  )
}
