import {Iso8601} from './atomic.types'
import {RunningTimesEquipmentData} from './shiftHandover.types'

export enum KpiRangeBreachSeverity {
  Alarm = 'alarm',
  Warning = 'warning',
  Optimal = 'optimal'
}

export type KpiAssignment = {
  kpiName: string
  result: {
    value: string
    unit: string
    startTime: Iso8601
    endTime: Iso8601
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
}

export type KpiPerformance = {
  kpiAssignments: KpiAssignment[]
}

export type EquipmentPerformance = {
  equipment: RunningTimesEquipmentData
  kpiPerformance?: KpiPerformance
}

export type BaseManualKpi = {
  name: string
  unit: string
  processStage: string
  actualValue?: number
  plannedValue?: number
  delta?: number
}

export type ManualKpi = BaseManualKpi & {
  id: string
  timestamp: Iso8601
  createdAt: Iso8601
  updatedAt: Iso8601
  concurrencyStamp: string
  resolution: ManualKpiResolution
}

export type ManualKpiToUpdate = BaseManualKpi & {
  id?: string // if id is supplied then its update of existing entity
  concurrencyStamp?: string
}

export enum ManualKpiResolution {
  Daily = 'Daily',
  PerShift = 'perShift'
}

export type ManualKpisProcessStage = {
  code: string
  name: string
}

export type ManualKpiSuggestion = {
  name: string
  unit: string
  processStage: string
}
