import {StoppageType} from '@hconnect/common/types'

export const STOPPAGE_TYPE_TRANSLATION_KEYS: {[key in StoppageType]: string} = {
  kiln: 'shiftEvent.stoppage.stoppageTypeKiln',
  cementMill: 'shiftEvent.stoppage.stoppageTypeCementMill',
  rawMill: 'shiftEvent.stoppage.stoppageTypeRawMill',
  coalMill: 'shiftEvent.stoppage.stoppageTypeCoalMill',
  crusher: 'shiftEvent.stoppage.stoppageTypeCrusher',
  rollerPress: 'shiftEvent.stoppage.stoppageTypeRollerPress',
  verticalMill: 'shiftEvent.stoppage.stoppageTypeVerticalMill',
  ballMill: 'shiftEvent.stoppage.stoppageTypeBallMill',
  dosingEquipment: 'shiftEvent.stoppage.stoppageTypeDosingEquipment',
  alternativeFuelInstallation: 'shiftEvent.stoppage.stoppageTypeAlternativeFuelInstallation',
  bypass: 'shiftEvent.stoppage.stoppageTypeBypass',
  other: 'shiftEvent.stoppage.stoppageTypeOther'
}

export const MAX_DESCRIPTION_CHARACTER_LIMIT = 5000

export const eventActionButtonSX = {
  py: 1.5,
  px: 2,
  fontWeight: 500
}
