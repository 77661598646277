import {datetimeFormatter, timeFormatter} from '@hconnect/uikit'
import {CollapsibleTableWithoutPagination, ColumnsConfig} from '@hconnect/uikit/src/lib2'
import {Box, Stack, Tooltip, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {KpiAssignment, KpiPerformance} from '../../types'
import {formatNumber} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'

import {KpiThresholdIcon} from './KpiThresholdIcon'

type Props = {
  kpiPerformance: KpiPerformance
  translationPrefix?: string
  showKpiStatusIndicator?: boolean
}

export const EquipmentKpiTable: React.FC<Props> = ({
  kpiPerformance,
  translationPrefix,
  showKpiStatusIndicator = false
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {kpiAssignments} = kpiPerformance

  const kpiAssignmentsWithValidValues = useMemo(() => {
    return kpiAssignments.filter((kpiAssignment) => {
      const value = parseFloat(kpiAssignment.result?.value)
      return !isNaN(value)
    })
  }, [kpiAssignments])

  const config: ColumnsConfig<KpiAssignment> = useMemo(() => {
    return [
      {
        key: 'kpiName',
        label: t(getTranslationKey('performance.label.kpi', translationPrefix)),
        template: ({rowData}) => {
          const start: string = rowData.result?.startTime
          const end: string = rowData.result?.endTime

          const isSameDay = moment(start).isSame(end, 'day')

          const tooltipText = `${datetimeFormatter(start, language)} - ${
            isSameDay ? timeFormatter(end, language) : datetimeFormatter(end, language)
          }`

          return (
            <Tooltip arrow title={tooltipText}>
              <Typography variant="body1">{rowData.kpiName}</Typography>
            </Tooltip>
          )
        },
        headerSx: {
          width: '50%'
        }
      },
      {
        key: 'actualValues',
        label: t(getTranslationKey('performance.label.actual', translationPrefix)),
        template: ({rowData}) => {
          const value = parseFloat(rowData.result?.value)
          const kpiBreachSeverity = rowData.result?.kpiBreachSeverity
          return (
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
              <Typography variant="body1">
                {!isNaN(value)
                  ? `${formatNumber(value, language)} ${rowData.result?.unit ?? ''}`
                  : '-'}
              </Typography>
              {showKpiStatusIndicator && !!kpiBreachSeverity && (
                <KpiThresholdIcon
                  status={kpiBreachSeverity}
                  dataTestId={`equipment-kpi-table-threshold-icon-${kpiBreachSeverity}`}
                />
              )}
            </Stack>
          )
        },
        headerSx: {
          width: '50%',
          textAlign: 'right'
        }
      }
    ]
  }, [t, language, translationPrefix, showKpiStatusIndicator])

  return (
    <Box data-test-id="equipment-kpi-table">
      <CollapsibleTableWithoutPagination<KpiAssignment>
        config={config}
        data={kpiAssignmentsWithValidValues}
      />
    </Box>
  )
}
