import {Circle, SquareRounded} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import React from 'react'

export const LegendItem = ({
  color,
  label,
  square
}: {
  color: string
  label: string
  square?: boolean
}) => (
  <Typography display="flex" variant="caption" alignItems="center" color="textPrimary">
    <Box
      component={square ? SquareRounded : Circle}
      width={15}
      mr={0.5}
      sx={{
        color: color,
        verticalAlign: 'text-bottom'
      }}
    />
    {label}
  </Typography>
)
