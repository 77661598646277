import {isoWeekDays, TimeZone} from '@hconnect/uikit'
import moment, {Moment} from 'moment-timezone'

import {DayTime, ParticularShift, Shift} from '../types'

export function dayTime(timeString: DayTime) {
  if (timeString.length !== 8 && timeString.length !== 12) {
    throw new Error('Invalid time input length passed')
  }
  const [hour, minute, rest] = timeString.split(':')
  const [second, millisecond = '0'] = rest.split('.')
  return {
    hour: Number.parseInt(hour, 10),
    minute: Number.parseInt(minute, 10),
    second: Number.parseInt(second, 10),
    millisecond: Number.parseInt(millisecond, 10)
  }
}
/**
 * technically the day starts at the start of the first shift,
 * until then its still the last shift of the previous day
 * so we will return the list of shifts, based on the start date of the shift the utcDate falls into
 * and not necessarily on the day of the given utcDate
 *
 * the parameter returnMatch & previousDayCheck are currently for internal use only
 */
export function getAllPossibleShiftsOfDay(
  utcDate: Moment,
  shifts: Shift[],
  timezone: TimeZone,
  previousDayCheck?: boolean
): {match: ParticularShift[]; list: ParticularShift[]} {
  if (!utcDate.isUTC()) {
    throw new Error('the given moment object is not utc')
  }

  const localDate = moment.tz(utcDate, timezone)
  const dayIndex = localDate.isoWeekday()

  const match: ParticularShift[] = []
  const result = shifts.reduce<ParticularShift[]>((list, shift) => {
    const dayRollOver =
      shift.start.localeCompare(shift.end, 'de') > 0 && !shift.end.startsWith('00:00:00')

    if (shift.weekDays.some((wd) => isoWeekDays.indexOf(wd) + 1 === dayIndex)) {
      const utcStart = localDate.clone().set(dayTime(shift.start)).utc()
      const utcEnd = localDate
        .clone()
        .set(dayTime(shift.endTechnical))
        .add(dayRollOver ? 1 : 0, 'day')
        .utc()
      const particularShift: ParticularShift = {
        ...shift,
        utcStartDate: utcStart.toISOString(),
        utcEndDate: utcEnd.toISOString()
      }

      // [] includes both the start date and the end date as we use the technical end time which avoids overlap
      if (localDate.isBetween(utcStart, utcEnd, undefined, '[]')) {
        match.push(particularShift)
      }

      list.push(particularShift)
    }
    return list
  }, [])

  if (match.length === 0) {
    // fix for bug HCP-18243
    // technically the day starts at the start of the first shift,
    // until then its still the last shift of the previous day
    // so if the given utc dose not fall in to todays shift's we check and return the previous days shifts

    if (previousDayCheck) {
      // prevent an endless loop
      throw new Error('did not find a suitable shift')
    }

    // WARNING this works only if all part of the day are covert by shifts
    // in case of "blank spots" this previousDayCheck might give miss leading results
    // returning the "nightshirt of last day"

    const endOfPreviousDay = localDate.clone().subtract(1, 'day').endOf('day')
    return getAllPossibleShiftsOfDay(endOfPreviousDay.utc(), shifts, timezone, true)
  }

  return {
    match,
    list: result
  }
}

export function getShift(utcDate: Moment, allShifts: Shift[], timezone: TimeZone) {
  const {match} = getAllPossibleShiftsOfDay(utcDate, allShifts, timezone)
  // A match should always be returned, and if not an exception would be thrown
  if (match.length === 0) {
    throw new Error('fail to find a shift for the given date')
  }

  // if (match.length >= 1) {
  //   // TBD what to do here as this should never happen
  //   // add a logging here maybe?
  // }

  return match[0]
}
