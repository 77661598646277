import {IsoWeekDay} from '@hconnect/uikit'

import {AppName} from '../hproduce'

import {Iso8601, Uuid} from './atomic.types'
import {RunningTime} from './runningTimes.types'
import {StoppageKind, shifthandoverStoppagesList} from './shifthandoverStoppages.types'

// Find a list of priorities in the usePriorities hook
export type Priority = number

export interface Attachment {
  id: number
  fileName: string
  mediaType: string
  url: string
  // preview is available for images and unavailable for pdfs or other docs
  previewMediaType?: string
  previewUrl?: string
}

export type PriorityItem = {
  priorityNumber: string
  priorityNumberText?: string | null
  language?: string | null
}

export enum PlantEquipmentType {
  KILN = 'kiln',
  RAW_MILL = 'rawmill',
  CEMENT_MILL = 'cementMill',
  ROLLER_PRESS = 'rollerPress'
}

export type PrioritiesTranslationMap = Map<Priority, string>

// RFC 7807 based Error payload
export type HttpProblem = {
  type: string
  title?: string
  detail?: string
  instance?: string
}

export type HttpError = HttpProblem & {code: number}

export type ShifthandoverStoppageCode = {
  code: string
  description: string
  type: StoppageKind
  stoppageCategory?: Category
}

export type DowntimeStoppageCode = {
  code: string
  description: string
  type: StoppageKind
  priority?: number
}

export const eventTypesInterchangeable = [
  'malfunction',
  'healthAndSafety',
  'task',
  'information',
  'idea',
  'environment',
  'parameterChange',
  'quality'
] as const
export type EventTypeInterchangeable = (typeof eventTypesInterchangeable)[number]
export const eventTypes = [...shifthandoverStoppagesList, ...eventTypesInterchangeable] as const
export type EventType = (typeof eventTypes)[number]

export const equipmentTypes = [
  'technicalId',
  'sapNumber',
  'processId',
  'mainEquipmentCustomId',
  'janusId'
] as const
export type EquipmentType = (typeof equipmentTypes)[number]

export enum EquipmentCategory {
  CEMENT = 'cement',
  CLINKER = 'clinker',
  RAW = 'raw',
  COAL = 'coal',
  UNKNOWN = 'unknown'
}

export const categories = [
  'mechanical',
  'electrical',
  'quality',
  'process',
  'environment',
  'healthAndSafety',
  'production',
  'cleaning',
  'cost',
  'other'
] as const
export type Category = (typeof categories)[number]

export const maintenanceNotifications = ['withNotifications', 'withoutNotifications'] as const
export type MaintenanceNotification = (typeof maintenanceNotifications)[number]

export type StoppageTypeWithReason =
  | 'cementMill'
  | 'rawMill'
  | 'coalMill'
  | 'crusher'
  | 'rollerPress'
  | 'verticalMill'
  | 'ballMill'
  | 'dosingEquipment'
  | 'alternativeFuelInstallation'
  | 'bypass'
  | 'other'
export type StoppageTypeWithCode = 'kiln' | 'cementMill' | 'rawMill'
export type StoppageType = 'kiln' | StoppageTypeWithReason

export type EquipmentData = {
  idType?: EquipmentType
  id: string
  text?: string
  equipmentCategory?: EquipmentCategory
  equipmentType?: StoppageType
}

export type RunningTimesEquipmentData = {
  idType?: EquipmentType
  id: string
  text?: string
  matchingId: string
  equipmentCategory?: EquipmentCategory
  equipmentType?: StoppageType
}

export type EquipmentRunningTimes = {
  equipment: RunningTimesEquipmentData
  runningTimes: RunningTime[]
  totalRunning: string
  totalStopped: string
  productionVolumePerHour: number
  operatingCoefficient: number
}

export type StoppageDetails =
  | {
      stoppageType: StoppageTypeWithCode
      stoppageCode: string
    }
  | {
      stoppageType: StoppageTypeWithReason
      stoppageReason: string
    }

export type ProcessStage = {
  plantId: string
  processStep: string
  processStepValue: string
}
export type EquipmentPlain = {
  equipment: EquipmentData
  processStage?: ProcessStage
  path?: EquipmentData[]
  mainEquipment?: EquipmentData
  defaultWorkCenterId?: string
  defaultPlannerGroup?: string
}

export const taskRepetitionUnits = ['daily', 'weekly', 'monthly', 'yearly'] as const
export type TaskRepetitionUnit = (typeof taskRepetitionUnits)[number]

export type WorkCenter = {id: string; text: string}

export enum WorkOrderTarget {
  Sap = 'sap',
  Infor = 'infor'
}

export type InforWorkOrder = {
  requestId: string
}

export type User = {
  id: Uuid
  name: string
}

export interface AttachmentFile {
  id?: number
  preview: string
  name: string
  contentType: string
  isImage?: boolean
  originalFile?: File
}

export interface Info {
  // username
  createdBy: User
  modifiedBy: string
  created: Iso8601
  lastModified: Iso8601
  lastModifiedStatus?: Iso8601
}

export interface Id {
  id: string
}

export type Assignees =
  | {
      assignees: User[]
    }
  | {
      assignees: never
    }

export const statusListWithCancellationOption = ['pending', 'ongoing', 'done', 'cancelled'] as const
export type StatusWithCancellation = (typeof statusListWithCancellationOption)[number]

export type QuickSelectRepetition = {
  repetition: TaskRepetitionUnit
  enabled?: boolean
}

type EndingOptions =
  | {endDate: Iso8601; endsAfterRepetitions?: never} // endDate or
  | {endDate?: never; endsAfterRepetitions: number} // endsAfterRepetitions or
  | {endDate?: never; endsAfterRepetitions?: never} // non

export type CustomDailyRepetition = EndingOptions & {
  repetition: 'daily'
  startDate: Iso8601
  repeatEvery: number
  enabled?: boolean
}

export type CustomWeeklyRepetition = EndingOptions & {
  repetition: 'weekly'
  startDate: Iso8601
  repeatEvery: number
  weekDays: IsoWeekDay[]
  enabled?: boolean
}

export type CustomTaskRepetition = CustomDailyRepetition | CustomWeeklyRepetition
export type TaskRepetition = QuickSelectRepetition | CustomDailyRepetition | CustomWeeklyRepetition

export type TaskPreviewData = TaskRepetition & {
  itemsCount: number
  startDate?: Iso8601
}

// Note: Not present in shifthandover events
// TODO: check with BE on implementation
export type EventAppInfo = {app: AppName; plantId: string; externalId}

export type Task = Assignees &
  Id &
  Info &
  EventAppInfo & {
    category: Category
    description: string
    dueDate: Iso8601
    eventType: 'task'
    status: StatusWithCancellation
    title: string

    // optional
    isHighlighted?: boolean
    toggleAssigneeNotification?: boolean
    equipment?: EquipmentData
    mainEquipment?: EquipmentData
    processStage?: string
    repetitionInfo?: TaskRepetition | null

    shiftId?: number | null // TODO double check
    priority?: Priority
  }

export type StatusWithNoneAndCancellationAndApproverStates =
  | 'none'
  | 'pending'
  | 'ongoing'
  | 'done'
  | 'cancelled'
  | 'approved'
  | 'rejected'
  | 'waitingForApproval'
