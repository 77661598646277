import {isUndefined} from 'lodash'
import {generatePath} from 'react-router-dom'

import {GRAPH_DETAIL_ROUTE} from '../consts'
import {MAINTAIN_HOST} from '../hproduce/config/maintain'
import {GraphDetailScreens} from '../types'

import {getHost} from './route.utils'

export const getMaintainAnalysisUrl = (plantId: string, analysisId?: number) => {
  if (isUndefined(analysisId)) {
    return '#'
  }

  const stageSpecificHost = getHost(MAINTAIN_HOST)
  const graphDetailPath = generatePath(GRAPH_DETAIL_ROUTE, {
    plantId,
    analysisId,
    type: GraphDetailScreens[0]
  })
  return stageSpecificHost + graphDetailPath
}
