import {Close, Square} from '@mui/icons-material'
import {Box, Button, Drawer, Typography} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentData, RunningTime, RunningTimeType} from '../../types'

import {getRunningTimeData, RUNNING_TIME_COLOR} from './common'

type OperationHoursDrawerProps = {
  isOpen: boolean
  onClose: () => void
  handleStoppageClick: (rt: RunningTime, isLast: boolean, equipment?: EquipmentData) => void
  stoppageData?: {
    runningTime: RunningTime
    isLast: boolean
    equipment?: EquipmentData
  }
  labels: {
    stoppage: string
    running: string
    editIncident: string
    createIncident: string
  }
}

export const OperationHoursDrawer: FC<OperationHoursDrawerProps> = ({
  isOpen,
  onClose,
  stoppageData,
  handleStoppageClick,
  labels
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return (
    <Drawer
      anchor={'bottom'}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {borderTopLeftRadius: 8, borderTopRightRadius: 8}
      }}
      BackdropProps={{
        sx: {background: 'none'}
      }}
    >
      {stoppageData && (
        <Box data-test-id="operation-hour-container-drawer" p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography
              variant="h3"
              display="inline-flex"
              alignItems="center"
              fontWeight={500}
              sx={{ml: {sm: 1}}}
            >
              {stoppageData.runningTime.runningTimeType && (
                <>
                  <Box
                    component={Square}
                    sx={{
                      color: RUNNING_TIME_COLOR[stoppageData.runningTime.runningTimeType],
                      verticalAlign: 'text-bottom',
                      mr: 1
                    }}
                  />
                  {stoppageData.equipment?.text}
                  {' - '}
                  {stoppageData.runningTime.runningTimeType === RunningTimeType.STOPPED
                    ? labels.stoppage
                    : labels.running}
                </>
              )}
            </Typography>
            <Box component={Close} onClick={onClose} fontSize={16} color="primary.main" />
          </Box>
          {stoppageData.runningTime.beginTechnical && (
            <Typography color="grey.600" mt={2}>
              {getRunningTimeData(stoppageData.runningTime, language, t)}
            </Typography>
          )}
          {stoppageData.runningTime.runningTimeType === RunningTimeType.STOPPED && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="text"
                onClick={() => {
                  onClose()
                  handleStoppageClick(
                    stoppageData.runningTime,
                    stoppageData.isLast,
                    stoppageData.equipment
                  )
                }}
                data-test-id="operation-hour-container-button"
              >
                {stoppageData.runningTime.cockpitStoppageId
                  ? labels.editIncident
                  : labels.createIncident}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Drawer>
  )
}
