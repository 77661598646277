import {RootCauseFailureAnalyses} from './analysis.types'
import {Iso8601} from './atomic.types'
import {StoppageKind} from './shifthandoverStoppages.types'

/** This is mainly used for stopped runningTimeType only.
 *  It is currently used in downtime dashboard.
 */
export type ThinRunningTime = {
  id: number
  beginTechnical: Iso8601
  endTechnical: Iso8601
  durationInHours: number
}

export type RunningTime = {
  id?: number
  beginTechnical?: Iso8601
  endTechnical?: Iso8601
  begin?: Iso8601
  end?: Iso8601
  runningTimeType?: RunningTimeType
  stoppageType?: string
  stoppageKind?: StoppageKind
  stoppageCode?: string
  stoppageReason?: string
  cockpitStoppageId?: string
  rootCauseFailureAnalyses?: RootCauseFailureAnalyses[]
}

export enum RunningTimeType {
  RUNNING = 'running',
  STOPPED = 'stopped'
}

export enum OtherRunningTimeTypes {
  DEFAULT = 'default',
  UNKNOWN = 'unknown'
}

export type AllRunningTimeTypes = RunningTimeType | OtherRunningTimeTypes
