import {EquipmentPerformance, EquipmentRunningTimes} from '../types'

const hasKpiAssignments = (equipmentPerformance: EquipmentPerformance): boolean => {
  const {kpiPerformance} = equipmentPerformance
  return !!kpiPerformance?.kpiAssignments?.length
}

export const checkIfKpiPerformanceIsAvailable = (
  equipmentPerformances?: EquipmentPerformance[]
): boolean => equipmentPerformances?.some(hasKpiAssignments) ?? false

export const filterEquipmentsWithKpiAssignments = (
  equipmentPerformances?: EquipmentPerformance[]
) => equipmentPerformances?.filter(hasKpiAssignments)

export const getUnmatchedEquipments = (
  equipmentPerformances?: EquipmentPerformance[],
  runningTimePerformances?: EquipmentRunningTimes[]
) =>
  runningTimePerformances?.filter(
    ({equipment}) =>
      !equipmentPerformances?.find(
        ({equipment: {matchingId}}) => matchingId === equipment.matchingId
      )
  )
