import {StoppageType} from '@hconnect/common/types'

const STOPPAGE_TYPE_ABBREVIATIONS = [
  'cm',
  'rm',
  'co',
  'cr',
  'ki',
  'rp',
  'vm',
  'bm',
  'de',
  'af',
  'bp',
  'ot'
] as const
export type StoppageTypeAbbreviation = (typeof STOPPAGE_TYPE_ABBREVIATIONS)[number]

export type StoppageCodesFilter = {
  type: StoppageType
  codes: string[]
}

const STOPPAGE_TYPE_ABBR_MAP: Record<StoppageType, StoppageTypeAbbreviation> = {
  cementMill: 'cm',
  rawMill: 'rm',
  coalMill: 'co',
  crusher: 'cr',
  kiln: 'ki',
  rollerPress: 'rp',
  verticalMill: 'vm',
  ballMill: 'bm',
  dosingEquipment: 'de',
  alternativeFuelInstallation: 'af',
  bypass: 'bp',
  other: 'ot'
} as const

export const toStoppageCodeSearchParam = (
  stoppageType: StoppageType,
  stoppageCode: string
): string => `${STOPPAGE_TYPE_ABBR_MAP[stoppageType]}${stoppageCode}`
