import {datetimeFormatter, getUtcOffset} from '@hconnect/uikit'
import {SimpleDatePicker, SimpleDatePickerProps} from '@hconnect/uikit/src/lib2'
import {Grid, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DayTime} from '../../types'

export function getHours(timeString: DayTime) {
  return Number.parseInt(timeString.split(':')[0]) || 0
}

export function getMinutes(timeString: DayTime) {
  return Number.parseInt(timeString.split(':')[1]) || 0
}
export function getHoursAndMinutesFromTimeString(timeString: DayTime) {
  return [getHours(timeString), getMinutes(timeString)]
}
function getTimeStringFromDate(d: Date | undefined | null) {
  if (!d) return undefined
  const hours = d.getHours()
  const hoursStr = hours < 10 ? `0${hours}` : hours.toString()
  const minutes = d.getMinutes()
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes.toString()

  return `${hoursStr}:${minutesStr}`
}

interface SimpleDateTimePickerProps extends SimpleDatePickerProps {
  variant?: 'standard' | 'filled' | 'outlined'
  showClearButton?: boolean
}

export const SimpleDateTimePicker: React.FC<SimpleDateTimePickerProps> = ({
  date,
  handleDateChange,
  variant,
  showClearButton,
  ...restProps
}) => {
  const timeString = getTimeStringFromDate(date)
  const {i18n} = useTranslation()
  const lang = i18n.language

  const handleDateChangeInternal = React.useCallback(
    (d: Date | null) => {
      // apply time to currently set date
      if (timeString && d) {
        const [hours, minutes] = getHoursAndMinutesFromTimeString(timeString)
        d.setHours(hours, minutes)
      }
      handleDateChange?.(d)
    },
    [timeString, handleDateChange]
  )
  const handleTimeChange = React.useCallback(
    (time: string) => {
      // everytime user enters something in the time field, bubble a date change event
      // where we set the entered time into the date field
      if (date) {
        const [hours, minutes] = getHoursAndMinutesFromTimeString(time)
        date.setHours(hours, minutes)
        handleDateChange?.(date)
      }
    },
    [handleDateChange, date]
  )

  const textFormatter = React.useCallback(() => {
    if (date) return datetimeFormatter(date, lang, getUtcOffset(date))
    else return ''
  }, [date, lang])

  const TimeCalendarWrapper = (innerPicker: React.ReactNode) => {
    const {t} = useTranslation()

    return (
      <div>
        {innerPicker}
        <Grid container style={{paddingTop: '1em'}}>
          <Grid item xs={12}>
            <TextField
              label={t('datePicker.time')}
              type="time"
              data-test-id="simple-date-time-picker-time"
              value={timeString}
              onChange={(ev) => handleTimeChange(ev.target.value)}
              style={{width: '100%'}}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
              variant={variant}
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div data-test-id="simple-date-time-picker">
      <SimpleDatePicker
        {...restProps}
        handleDateChange={handleDateChangeInternal}
        date={date}
        noAutoClose={true}
        textFormatter={textFormatter}
        calendarWrapper={TimeCalendarWrapper}
        fullWidth
        showClearButton={showClearButton}
      />
    </div>
  )
}
