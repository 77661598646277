import {datetimeFormatter, timeFormatter} from '@hconnect/uikit/src/common'
import {TFunction} from 'i18next'
import moment from 'moment'
import React from 'react'

import {RunningTime, RunningTimeType} from '../../types'

export const RUNNING_TIME_COLOR = {
  stopped: 'error.light',
  running: 'success.light',
  planned: 'grey.600'
}

export const getRunningTimeData = (
  {beginTechnical, endTechnical, runningTimeType}: RunningTime,
  language: string,
  t: TFunction
) => {
  const isDateSame = moment(beginTechnical).isSame(endTechnical, 'day')

  return beginTechnical ? (
    <>
      {datetimeFormatter(beginTechnical, language)}
      {endTechnical && moment(endTechnical).isValid()
        ? ` - ${
            isDateSame
              ? timeFormatter(endTechnical, language)
              : datetimeFormatter(endTechnical, language)
          }`
        : ` - ${
            runningTimeType === RunningTimeType.STOPPED
              ? t('stackedBarChart.label.stillStopped')
              : t('stackedBarChart.label.stillRunning')
          }`}
    </>
  ) : (
    '-'
  )
}
